// @ts-check
import {createContext} from "react";

export const DEFAULT_TIMEZONE = "Europe/Berlin";
export const DEFAULT_LOCALE = "de";
/**
 * @typedef {Object} Locale
 * @property {String} locale
 */

/** @typedef {(DateProperties & Locale)} DatePropertiesWithLocale */

/**
 * @typedef {Object} InitialDateContext
 * @property {String} timezone
 * @property {String} locale
 */

const initialContext = {
    timezone: DEFAULT_TIMEZONE,
    locale: DEFAULT_LOCALE
};
/** @type {React.Context<DatePropertiesWithLocale>} */
const DateContext = createContext(initialContext);

export default DateContext;
