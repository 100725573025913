import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        position: "relative",
        height: "100%",
        width: "100%",
        background: "#fdfdfd",
        display: "grid",
        overflow: "auto"
        // cursor: "grab"
    },
    isScrolling: {
        cursor: "grabbing"
    },
    grid: {
        display: "flex",
        flexWrap: "nowrap"
    },
    gridColTimes: {
        height: "1.875rem"
    },
    gridColRooms: {
        width: "4rem",
        position: "sticky",
        left: 0,
        background: theme.palette.background.paper,
        borderRight: `1px solid ${theme.palette.border}`,
        zIndex: theme.custom.zIndex.canvas + 140
    },
    roomLabel: {
        borderBottom: `1px solid ${theme.palette.border}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch"
    },
    roomLabelNew: {
        height: "5rem"
    },
    gridItemHeader: {
        height: "1.875rem",
        position: "sticky",
        background: theme.palette.background.paper,
        top: 0,
        zIndex: theme.custom.zIndex.canvas + 20
    },
    gridItem: {
        borderBottom: `1px solid ${theme.palette.border}`
    },
    rowWrapper: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        borderBottom: `1px solid ${theme.palette.border}`
    },
    room: {
        lineHeight: "1.875rem",
        textAlign: "center",
        fontSize: "0.75rem",
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightMedium,
        position: "sticky",
        top: 0,
        background: theme.palette.background.paper,
        zIndex: theme.custom.zIndex.canvas + 150,
        borderBottom: `1px solid ${theme.palette.border}`
    },
    time: {
        position: "absolute",
        lineHeight: "1.875rem",
        fontSize: "0.75rem",
        flexGrow: 0,
        flexShrink: 0,
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: "center"
    },
    yaxisLabel: {
        fontSize: theme.spacing("m"),
        color: theme.palette.grey[500],
        textAlign: "center",
        fontWeight: theme.typography.fontWeightMedium,
        width: "4rem",
        height: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    vlines: {
        position: "absolute",
        background: theme.palette.border,
        width: "1px",
        zIndex: theme.custom.zIndex.canvas + 1
    },
    blocked: {
        backgroundColor: theme.palette.grey[100]
    },
    blockerIconWrapper: {
        position: "absolute",
        bottom: 0,
        right: "2px",
        cursor: "pointer"
    },
    blockerIcon: {
        color: theme.custom.emergency.main,
        fontSize: "1rem"
    },
    addArea: {
        "display": "flex",
        "justifyContent": "flex-end",
        "alignItems": "center",
        "&:hover": {
            "border": `2px solid ${theme.palette.primary.main}`,
            "position": "relative",
            "zIndex": `${theme.custom.zIndex.canvas + 6}`, // necessary to make left border visible
            "& svg": {
                color: theme.palette.primary.main
            }
        }
    },
    cursor: {
        cursor: "pointer"
    },
    iconButton: {
        padding: "8px"
    },
    addIcon: {
        color: theme.palette.border,
        fontSize: "1.2rem"
    },
    disabled: {
        color: theme.palette.text.disabled
    }
}));
