import {makeStyles} from "tss-react/mui";

export default makeStyles()(() => ({
    root: {
        top: 0,
        left: "3rem",
        width: "calc(100% - 3rem)",
        height: "100%",
        position: "absolute"
    },
    header: {
        "display": "flex",
        "& ul": {
            margin: 0,
            padding: 0
        },
        "height": "1.3rem",
        "textOverflow": "ellipsis"
    }
}));
