// @ts-check
import {useEffect, useRef} from "react";

/** @typedef {import("react-window").VariableSizeList<any>} VariableList */
/** @typedef {import("react").MutableRefObject<VariableList>} Ref */

/**
 * A custom hook to reset cached data on 'react-window' variable lists
 *
 * @description This hook is necessary because itemSize is not triggered even if itemData changes.
 * @link https://github.com/bvaughn/react-window/issues/147
 *
 * @param {any} data
 * @return {React.MutableRefObject<VariableList>}
 */
export const useResetCache = (data) => {
    /** @type Ref */
    const ref = useRef();
    useEffect(() => {
        // AutoSizer causes ref not being present on the component mount
        if (ref.current) {
            ref.current.resetAfterIndex(0);
        }
    }, [data]);
    return ref;
};
