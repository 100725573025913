import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {useSelector} from "react-redux";

import {selectCoreValues, selectOpRoomIds} from "../../../../pages/timeslots/timeslots_selectors";
import useStyles from "../../timeslot_canvas.styles";

/**
 * render vertical lines from 00:00 to 24:00
 * @return {JSX}
 */
const VerticalLines = ({height}) => {
    const {classes} = useStyles();

    const coreValues = useSelector(selectCoreValues);
    const opRoomIds = useSelector(selectOpRoomIds);

    const vlines = [];

    if (!coreValues.rowHeight || opRoomIds.length === 0) return null;
    for (let i = 1; i <= coreValues.canvasEnd; i++) {
        vlines.push(
            <div
                className={classes.vlines}
                data-testid={"v-line"}
                key={"v-lines-" + i}
                style={{left: i * coreValues.widthPerHour + "px", height: height}}
            />
        );
    }
    return <Fragment>{vlines}</Fragment>;
};

VerticalLines.propTypes = {
    height: PropTypes.string.isRequired
};

export default VerticalLines;
