import {AddCircleOutline} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {saveCoreValues, saveOpRooms} from "../../pages/timeslots/timeslots_actions";
import {
    selectCoreValues,
    selectData,
    selectLoadStatus,
    selectOpRoomIds,
    selectSelectedDate
} from "../../pages/timeslots/timeslots_selectors";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import {isResolved} from "../../redux/utils/status";
import {PERMISSION, useSecurity} from "../../utils/security";
import sortLocation from "../../utils/sort_location";
import {loadDisciplineOptionsAction} from "../disciplines/disciplines_actions";
import {selectThemeLocationInfos, selectThemeStartTimeOfTheDay} from "../fe_settings/fe_settings_selectors";
import {loadRoomsAction} from "../rooms/rooms_actions";
import {selectRoomInfos} from "../rooms/rooms_selectors";
import NoOp from "../shared/no_op/no_op";
import PermissionTooltip from "../shared/permission_tooltip/permission_tooltip";
import TimeslotBoxContainer from "./components/timeslot_box_container/timeslot_box_container";
import VerticalLines from "./components/vertical_lines/vertical_lines";
import YaxisElement from "./components/yaxis_element/yaxis_element";
import useStyles from "./timeslot_canvas.styles";

/**
 * canvas for timeslots
 * @param {Object} props.onClick
 * @param {Function} onClick
 * @return {React.ReactElement}
 */
const TimeslotCanvas = ({onClick}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(null);
    const {isGranted} = useSecurity();
    const {startOf, endOf, areSame, fromISO, format, getDT, setDT} = useContext(DateContext);

    const isAddAllowed = isGranted(PERMISSION.MODIFY_HCSERVICE);

    // redux store
    const organizationId = useSelector(selectCurrentOrganizationId);
    const selectedDate = useSelector(selectSelectedDate);
    const opRooms = useSelector(selectOpRoomIds);
    const coreValues = useSelector(selectCoreValues);
    const loadStatus = useSelector(selectLoadStatus);
    const timeslots = useSelector(selectData);
    const roomInfos = useSelector(selectRoomInfos);
    const startTimeOfTheDay = useSelector(selectThemeStartTimeOfTheDay);
    const locationInfos = useSelector(selectThemeLocationInfos);

    const [slotByRooms, setSlotByRooms] = useState({}); // set slots by rooms {"ROOM1": [{slot1}, {slot2}], "ROOM2": []}

    useEffect(() => {
        dispatch(loadRoomsAction(organizationId));
        dispatch(loadDisciplineOptionsAction(organizationId));
    }, []);

    useEffect(() => {
        if (timeslots.length && roomInfos.length && selectedDate) {
            analyzeOpData();
        }
    }, [selectedDate, timeslots, roomInfos]);

    useLayoutEffect(() => {
        if (ref.current) {
            scrollToInitialHour();
        }
    }, [ref.current]);

    /**
     * scroll to start business hour
     */
    const scrollToInitialHour = () => {
        const scrollLeft = (startTimeOfTheDay || 8) * coreValues.widthPerHour;
        const maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
        ref.current.scrollLeft = scrollLeft > maxScrollLeft ? maxScrollLeft : scrollLeft;
    };

    /**
     * analyze timeslots data
     */
    const analyzeOpData = () => {
        // Calculate earliest op enter time and latest op exit time
        let minStartTime = endOf(selectedDate, "day");
        let maxEndTime = startOf(selectedDate, "day");
        const grouping = {};

        for (const slot of timeslots) {
            const {start: timeStart, end: timeEnd} = slot.preCalcDates[0];
            const opRoom = slot.locations?.[0];

            const timeStartDT = fromISO(timeStart);
            const timeEndDT = fromISO(timeEnd);

            if (opRoom !== null && areSame(timeStartDT, selectedDate, "day")) {
                if (!grouping[opRoom]) {
                    grouping[opRoom] = [slot];
                } else {
                    grouping[opRoom].push(slot);
                }
                if (timeStartDT < minStartTime) {
                    minStartTime = timeStartDT;
                }
                if (timeEndDT > maxEndTime) {
                    maxEndTime = timeEndDT;
                }
            }
        }
        setSlotByRooms(grouping);

        const opRooms = Object.keys(grouping);
        // Sort by room name
        dispatch(saveOpRooms([...opRooms].sort(sortLocation(locationInfos))));
        dispatch(
            saveCoreValues({
                ...coreValues,
                rowCount: opRooms.length
            })
        );
    };

    /**
     * handler to adding a new slot
     * @param {String} roomId
     * @param {DateTimeType} start
     */
    const handleAdd = (roomId, start) => {
        const startHour = getDT(start, "hour") + getDT(start, "minute") / 60;
        onClick({
            organizationId: organizationId,
            isEmergency: false,
            interval: "none",
            locations: [roomId],
            healthcareServices: [],
            startDate: format(startOf(start, "day"), DATE_FORMATS.ISO_DATE),
            endDate: format(endOf(start, "day"), DATE_FORMATS.ISO_DATE),
            hourStartLocalTime: startHour,
            hourEndLocalTime: startHour + 1
        });
    };

    if (isResolved(loadStatus) && timeslots.length === 0) {
        return (
            <NoOp
                text1={t("App.noEntryFound1")}
                text2={t("App.noEntryFound2", {
                    date: format(selectedDate, DATE_FORMATS.DATE)
                })}
            />
        );
    }

    if (!selectedDate) return null;

    // Prepare for rendering
    const timelabels = [];
    const startTimes = [];
    for (let i = 0; i <= coreValues.canvasEnd; i++) {
        timelabels.push((i % 24).toString().padStart(2, "0").concat(":00"));
        startTimes.push(setDT(startOf(selectedDate, "day"), {hour: i}));
    }
    return (
        <div className={classes.root} ref={ref}>
            {isResolved(loadStatus) && (
                <div className={classes.grid}>
                    <div className={classes.gridColRooms}>
                        <div className={cx(classes.gridColTimes, classes.room)}>{t("OpManagement.rooms")}</div>
                        <YaxisElement coreValues={coreValues} opRooms={opRooms} />
                    </div>
                    <div style={{width: coreValues.widthPerHour * (coreValues.canvasEnd + 1) + "px"}}>
                        <div className={cx(classes.gridColTimes, classes.gridItemHeader)}>
                            <div className={classes.rowWrapper}>
                                {timelabels.map((time, i) => (
                                    <div
                                        className={classes.time}
                                        key={time}
                                        style={{
                                            left: i * coreValues.widthPerHour + "px",
                                            width: coreValues.widthPerHour + "px"
                                        }}
                                    >
                                        {time}
                                    </div>
                                ))}
                                <VerticalLines height="100%" />
                            </div>
                        </div>
                        {opRooms.map((room, i) => (
                            <div
                                className={classes.rowWrapper}
                                key={"canvas-row-" + room}
                                style={{
                                    height: coreValues.rowHeight + "px"
                                }}
                            >
                                {startTimes.map((start) => (
                                    <div
                                        className={cx(classes.addArea, {
                                            [classes.cursor]: isAddAllowed
                                        })}
                                        key={`add-${room}-${format(start, DATE_FORMATS.ISO_DATE)}`}
                                        role={"button"}
                                        style={{height: coreValues.rowHeight + "px", width: coreValues.widthPerHour + "px"}}
                                        tabIndex={0}
                                        onClick={() => handleAdd(room, start)}
                                        onKeyDown={() => handleAdd(room, start)}
                                    >
                                        <PermissionTooltip isAllowed={isAddAllowed}>
                                            <IconButton className={classes.iconButton} disabled={!isAddAllowed} size="large">
                                                <AddCircleOutline
                                                    className={isAddAllowed ? classes.addIcon : classes.disabled}
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        </PermissionTooltip>
                                    </div>
                                ))}
                                {i === 0 && <VerticalLines height={`${opRooms.length * coreValues.rowHeight}px`} />}
                                {!!Object.keys(slotByRooms).length && (
                                    <TimeslotBoxContainer slots={slotByRooms[room] || []} onClick={onClick} />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

TimeslotCanvas.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default TimeslotCanvas;
