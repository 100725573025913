import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    ghostLayer: {
        height: "102%", // to cover the borders of slots on-hover
        opacity: 0
    },
    root: {
        position: "absolute",
        background: "transparent",
        border: "1px solid transparent",
        borderRadius: "5px",
        zIndex: theme.custom.zIndex.canvas + 5,
        backgroundColor: "#fff"
    },
    box: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "stretch",
        borderRadius: "4px",
        boxShadow: theme.shadows[2],
        overflow: "hidden",
        padding: "3px",
        cursor: "pointer",
        zIndex: theme.custom.zIndex.canvas + 7
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        padding: "0.125rem",
        position: "absolute",
        left: theme.spacing("m"),
        width: "calc(100% - 1rem)"
    },
    contentRow: {
        fontSize: "11px",
        padding: "0 0.25rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: "1.2rem",
        color: theme.palette.grey[900],
        fontWeight: theme.typography.fontWeightLight
    },
    contentRowEmergency: {
        color: theme.custom.emergency.main
    },
    medium: {
        fontSize: "14px",
        fontWeight: theme.typography.fontWeightMedium
    },
    rootEmergency: {
        "backgroundColor": theme.custom.emergency.light,
        "&:hover": {
            border: `2px solid ${theme.custom.emergency.main}`
        }
    },
    rootHovered: {
        "&:hover": {
            border: `2px solid ${theme.palette.primary.main}`
        }
    },
    rootSelectedSlot: {
        border: `1px solid ${theme.palette.primary.main}`
    },
    disciplineLine: {
        width: "13px",
        height: "100%",
        borderRadius: "2px"
    }
}));
