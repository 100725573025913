// @ts-check
import {t} from "i18next";

import {DATE_FORMATS} from "../../contexts/dates";
import {sortArrayOfObjectsByKey} from "../../utils/sort_array_of_objects_by_key";
const ALL = "all";
export const INVALID = "invalid";

// /** @typedef {("IDLE"|"INVALIDATED"|"PENDING")|"RESOLVED"|"REJECTED")} Status */
/** @typedef {import("react-hook-form").UseFormSetError<any>} SetError */

/**
 * Adds the option "all" to the array of options in case isEmergency is true
 *
 * @param {Array<{value: string, label: string}>} items
 * @return {Array<{value: string, label: string}>}
 */
export const addAllOption = (items) => [
    {value: "all", label: t("TimeslotsPage.allDisciplines")},
    {value: "divider", label: "divider"},
    ...items
];

/**
 * Get hour local time from the given DateTime (16:30 will be 16.5)
 *
 * @param {DateTimeType} time A DateTime object with the current time
 * @param {Get} getDT A Function to get a certain unit of given DateTime
 * @return {object}
 */
export const getHourLocalTime = (time, getDT) => getDT(time, "hour") + getDT(time, "minute") / 60;

/**
 * Formats the period for the RHF
 *
 * @param {object} params
 * @param {DateTimeType} params.selectedDate The selected data
 * @param {StartOf} params.startOf get start of the given day
 * @param {EndOf} params.endOf get end of the given day
 * @return {{start: DateTimeType, end: DateTimeType}}
 */
export const formatPeriod = ({selectedDate, startOf, endOf}) => ({
    start: startOf(selectedDate, "day"),
    end: endOf(selectedDate, "day")
});

/**
 * Formats the period for the RHF
 *
 * @param {object} params
 * @param {Now} params.now
 * @param {SetDate} params.setDT
 * @param {Number} params.startTimeOfTheDay
 * @return {{start: DateTimeType, end: DateTimeType}}
 */
export const formatTime = ({now, setDT, startTimeOfTheDay}) => ({
    start: setDT(now(), {hour: startTimeOfTheDay || 8, minute: 0, second: 0, millisecond: 0}),
    end: setDT(now(), {hour: 17, minute: 0, second: 0, millisecond: 0})
});

/**
 * Formats and translate the list of disciplines
 *
 * @param {Array<{id: string, name: string}>} disciplinesIdsAndName
 * @return {Array<{value: string, label: string}>}
 */
export const formatDisciplines = (disciplinesIdsAndName) =>
    disciplinesIdsAndName
        .map((el) => ({value: el.id, label: t([`HealthcareService.${el.id}`, el.name])}))
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));

/**
 * Checks if there any conflicting dates with the discipline slot that wants to be saved
 *
 * @param {object} params
 * @param {Function} params.isResolved
 * @param {Status} params.checkStatus
 * @param {{conflicts: Array, totalCount: number, preCalcDates: array}} params.preCheck
 * @return {Array<String>} The conflict dates in case there are
 */
export const checkForConflictDates = ({isResolved, checkStatus, preCheck}) => {
    let conflictDates = [];
    if (isResolved(checkStatus) && preCheck && preCheck.conflicts) {
        preCheck.conflicts.map((conflict) => {
            conflictDates.push(conflict.existingSlot.start);
        });
    }
    conflictDates = [...new Set(conflictDates)];
    conflictDates.sort();
    return conflictDates;
};

/**
 * Checks if the dates set for the weeks are valid. It is used just when selectedInterval === "user-defined"
 *
 * @param {object} props
 * @param {String} props.interval
 * @param {DateTimeType} props.dateEnd
 * @param {DateTimeType} props.dateStart
 * @param {string} props.customWeeks the number of weeks but in string
 * @param {Diff} props.diffDT
 * @return {boolean}
 */
export const areWeeksInvalid = ({interval, dateEnd, dateStart, customWeeks, diffDT}) =>
    interval === "user-defined" && dateEnd ? diffDT(dateEnd, dateStart, "days") <= parseInt(customWeeks) * 7 - 1 : false;

/**
 * Get the discipline from the timeslot call depending on wether isEmergency === true or not
 *
 * @param {object} props
 * @param {boolean} props.isEmergency
 * @param {Array<string>} props.healthcareServices
 * @return {string|Array<string>}
 */
export const getDiscipline = ({isEmergency, healthcareServices}) => {
    if (isEmergency) return healthcareServices.length > 0 ? [...healthcareServices] : [ALL];
    return healthcareServices[0];
};

/**
 * Handles the logic when "All specialties" is selected.
 * We need this extra function because "All specialties" has to behave as
 * an exclusive value in a Material UI Multiple Select.
 * This functions rely on the fact that the last clicked checkbox is the last
 * element on the value array.
 *
 * @param {Array<String>} value - The list of selected values
 * @param {Function} setValue - A function to set RHK values
 */
export const handleCheckboxAll = (value, setValue) => {
    const isAllSelectedLast = value[value.length - 1] === "all";
    const isAllChecked = value.some((v) => v === "all");

    if (isAllSelectedLast) {
        setValue("disciplinesSelectMultiple", ["all"]);
    } else if (isAllChecked) {
        const valuesWithoutAll = value.filter((v) => v !== "all");
        setValue("disciplinesSelectMultiple", valuesWithoutAll);
    }
};

/**
 *
 * @param {object} params
 * @param {boolean} params.hasNoEndDate
 * @param {DateTimeType} params.dateStart
 * @param {DateTimeType} params.dateEnd
 * @param {Interval} params.interval
 * @param {EndOf} params.endOf
 * @param {Format} params.format
 * @return {string|undefined}
 */
export const getEndDate = ({hasNoEndDate, dateStart, interval, dateEnd, endOf, format}) => {
    if (interval === "none") return format(endOf(dateStart, "day"), DATE_FORMATS.ISO_DATE);
    if (hasNoEndDate) return undefined;
    return dateEnd?.isValid ? format(endOf(dateEnd, "day"), DATE_FORMATS.ISO_DATE) : INVALID;
};

/**
 * Return the DateTime time for a given hour
 *
 * @param {object} params
 * @param {number} params.hour
 * @param {Now} params.now
 * @param {SetDate} params.setDT
 * @return {DateTimeType}
 */
const getDateTimeFromHour = ({hour, now, setDT}) =>
    setDT(now(), {hour: Math.floor(hour), minute: (hour - Math.floor(hour)) * 60, second: 0, millisecond: 0});

/**
 * Get the default values when creating a slot for a NEW OP
 *
 * @param {Object} params
 * @param {{start: DateTimeType, end: DateTimeType}} params.time
 * @param {{start: DateTimeType, end: DateTimeType}} params.period
 * @return {DefaultValuesOfTimeslot}
 */
export const getDefaultValues = ({period, time}) => {
    /** @type {DefaultValuesOfTimeslot} */
    const defaultValues = {
        disciplinesSelectMultiple: [],
        disciplinesSelectSingle: "",
        roomId: "",
        interval: "none",
        customWeeks: "1",
        dateRange_start: period.start.toJSDate(),
        dateRange_end: undefined,
        timeRange_start: time.start.toJSDate(),
        timeRange_end: time.end.toJSDate(),
        hasNoEndDate: false,
        radioGroup: "elective",
        frequency: []
    };
    return defaultValues;
};

/**
 * Get default Form Values when opening an EXISTING OP
 *
 * @param {Object} params
 * @param {TimeSlot} params.timeslot
 * @param {Now} params.now
 * @param {SetDate} params.setDT
 * @param {FromISO} params.fromISO
 * @return {DefaultValuesOfTimeslot}
 */
export const getDefaultValuesWithTimeslot = ({timeslot, now, setDT, fromISO}) => {
    const {repeatAfterDays, hourEndLocalTime, hourStartLocalTime, interval, startDate, endDate, isEmergency, healthcareServices} = timeslot;

    const customWeeksFormatted = (repeatAfterDays / 7).toString();
    const discipline = getDiscipline({isEmergency, healthcareServices});
    const roomId = timeslot.locations?.[0] || "";
    const timeRange_start = getDateTimeFromHour({hour: hourStartLocalTime, now, setDT}).toJSDate();
    const timeRange_end = getDateTimeFromHour({hour: hourEndLocalTime, now, setDT}).toJSDate();
    const dateRange_start = fromISO(startDate).toJSDate();
    const dateRange_end = endDate === null ? undefined : fromISO(endDate).toJSDate();

    /** @type {"emergency"|"elective"} */
    const radioGroup = isEmergency ? "emergency" : "elective";
    const disciplinesSelectSingle = !isEmergency && typeof discipline === "string" ? discipline : "";
    const disciplinesSelectMultiple = isEmergency && Array.isArray(discipline) ? discipline : [];
    const customWeeks = interval === "user-defined" ? customWeeksFormatted : "1";
    const hasNoEndDate = !endDate;
    const frequency = timeslot?.frequency || [];

    const defaultValuesWithTimeslot = {
        disciplinesSelectMultiple,
        disciplinesSelectSingle,
        roomId,
        interval,
        customWeeks,
        dateRange_start,
        dateRange_end,
        timeRange_start,
        timeRange_end,
        hasNoEndDate,
        radioGroup,
        frequency
    };
    return defaultValuesWithTimeslot;
};

const WEEK_DAYS = 7;

/**
 *
 * @param {object} params
 * @param {DefaultValuesOfTimeslot} params.data
 * @param {boolean} params.isEmergency
 * @param {string} params.organizationId
 * @param {string} params.email
 * @param {string|null} params.id
 * @param {object} params.dateFunc
 * @param {Format} params.dateFunc.format
 * @param {FromJSDate} params.dateFunc.fromJSDate
 * @param {StartOf} params.dateFunc.startOf
 * @param {Get} params.dateFunc.getDT
 * @param {EndOf} params.dateFunc.endOf
 * @return {DataToPostTimeslot}
 */
export const formatTimeslotData = ({
    data,
    isEmergency,
    organizationId,
    email,
    id,
    dateFunc: {format, fromJSDate, startOf, getDT, endOf}
}) => {
    const {
        disciplinesSelectSingle,
        disciplinesSelectMultiple,
        roomId,
        customWeeks,
        interval,
        frequency,
        dateRange_start,
        dateRange_end,
        timeRange_start,
        timeRange_end,
        hasNoEndDate
    } = data;
    // format date & time
    const startDate = format(startOf(fromJSDate(dateRange_start), "day"), DATE_FORMATS.ISO_DATE);
    const endDate = getEndDate({
        hasNoEndDate,
        dateEnd: fromJSDate(dateRange_end),
        interval,
        dateStart: fromJSDate(dateRange_start),
        endOf,
        format
    });
    const hourStartLocalTime = getHourLocalTime(fromJSDate(timeRange_start), getDT);
    const hourEndLocalTime = getHourLocalTime(fromJSDate(timeRange_end), getDT);

    const repeatAfterDays = parseInt(customWeeks) * WEEK_DAYS;
    const healthcareServiceIds = isEmergency ? [...disciplinesSelectMultiple] : [disciplinesSelectSingle];
    const location = roomId;

    const dataToPost = {
        organizationId,
        healthcareServiceIds,
        isEmergency,
        location,
        interval,
        hourStartLocalTime,
        hourEndLocalTime,
        startDate,
        endDate,
        email
    };
    if (id) dataToPost.id = id;

    if (interval === "user-defined") {
        dataToPost.repeatAfterDays = repeatAfterDays;
        dataToPost.frequency = frequency;
    }
    return dataToPost;
};

/**
 *
 * @param {object} params
 * @param {DefaultValuesOfTimeslot} params.formData
 * @param {object} params.dateFunc
 * @param {FromJSDate} params.dateFunc.fromJSDate
 * @param {EndOf} params.dateFunc.endOf
 * @param {Format} params.dateFunc.format
 * @param {Diff} params.dateFunc.diffDT
 * @return {{isEndTimeSameOrEarlier: boolean, isEndDateInvalid: boolean, hasDateRangeWithUserDefinedError: boolean, hasFrequencyError: boolean}}
 */
export const validateData = ({formData, dateFunc: {fromJSDate, endOf, format, diffDT}}) => {
    const {customWeeks, interval, frequency, dateRange_start, dateRange_end, timeRange_start, timeRange_end, hasNoEndDate} = formData;

    const endDate = getEndDate({
        hasNoEndDate,
        dateEnd: fromJSDate(dateRange_end),
        interval,
        dateStart: fromJSDate(dateRange_start),
        endOf,
        format
    });

    const invalidWeeks = areWeeksInvalid({
        interval,
        dateEnd: fromJSDate(dateRange_end),
        dateStart: fromJSDate(dateRange_start),
        customWeeks,
        diffDT
    });
    let hasDateRangeWithUserDefinedError = false;
    let hasFrequencyError = false;
    if (interval === "user-defined") {
        if (invalidWeeks && !hasNoEndDate) {
            hasDateRangeWithUserDefinedError = true;
        }
        if (frequency.length === 0) {
            hasFrequencyError = true;
        }
    }
    const isEndTimeSameOrEarlier = timeRange_end && fromJSDate(timeRange_end) <= fromJSDate(timeRange_start);
    const isEndDateInvalid = endDate === INVALID;

    return {isEndTimeSameOrEarlier, isEndDateInvalid, hasDateRangeWithUserDefinedError, hasFrequencyError};
};

/**
 * Validate and save the timeslot data
 *
 * @param {object} params
 * @param {DefaultValuesOfTimeslot} params.formData
 * @param {{isEmergency: boolean, organizationId: string, email: string, id: string}} [params.baseData] the base data for save, must be set if the skipSave is false
 * @param {{diffDT: Diff, endOf: EndOf, format: Format, fromJSDate: FromJSDate, getDT: Get, startOf: StartOf}} params.dateFunc
 * @param {Function} params.handleSave
 * @param {boolean} params.skipSave it will be set to handleSave handler, the data is only checked if true. the formData will be checked and saved if false.
 * @param {SetError} [params.setError] set errors for the forms if the function is provided
 */
export const validateAndSaveTimeslot = ({
    formData,
    baseData,
    dateFunc: {diffDT, endOf, format, fromJSDate, getDT, startOf},
    handleSave,
    skipSave,
    setError
}) => {
    const {isEndDateInvalid, isEndTimeSameOrEarlier, hasDateRangeWithUserDefinedError, hasFrequencyError} = validateData({
        formData,
        dateFunc: {fromJSDate, endOf, format, diffDT}
    });
    if (typeof setError === "function") {
        if (isEndTimeSameOrEarlier) {
            setError("timeRange_end", {type: "invalid", message: "Form.startTimeBeforeEnd"});
        }
        if (isEndDateInvalid) {
            setError("dateRange_end", {type: "invalid", message: "Form.checkFields"});
        }
        if (hasDateRangeWithUserDefinedError) {
            setError("dateRange_end", {type: "invalid", message: "Form.correctTimeForWeeks"});
        }
        if (hasFrequencyError) {
            setError("frequency", {type: "invalid", message: "Form.selectFrequency"});
        }
    }
    // Make the call to the BE if there are no errors
    if (!hasDateRangeWithUserDefinedError && !hasFrequencyError && !isEndTimeSameOrEarlier && !isEndDateInvalid) {
        const dateFunc = {format, fromJSDate, startOf, getDT, endOf};
        handleSave(formatTimeslotData({data: formData, ...baseData, dateFunc}), skipSave);
    }
};
