import {makeStyles} from "tss-react/mui";

/**
 * @type {Object}
 */
export default makeStyles()((theme) => ({
    button: {
        fontSize: "0.688rem",
        fontWeight: theme.typography.fontWeightBold,
        textTransform: "uppercase"
    },
    dot: {
        width: theme.spacing("s"),
        height: theme.spacing("s"),
        borderRadius: theme.spacing("xs"),
        marginTop: "0.4rem",
        marginRight: theme.spacing("m"),
        flexShrink: 0,
        backgroundColor: theme.palette.grey[200] // fallback color
    },
    dotEmergency: {
        backgroundColor: theme.custom.emergency.main
    },
    text: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
}));
