import * as yup from "yup";

export const TimeSlotSchema = yup.object().shape({
    roomId: yup.string().required("Form.checkFields"),
    disciplinesSelectSingle: yup.string().when("radioGroup", {
        is: (val) => val === "elective",
        then: () => yup.string().required("Form.checkFields"),
        otherwise: () => yup.string().nullable()
    }),
    disciplinesSelectMultiple: yup.array().when("radioGroup", {
        is: (val) => val === "emergency",
        then: () => yup.array().min(1, "Form.checkFields"),
        otherwise: () => yup.array().nullable()
    }),
    dateRange_start: yup.date().typeError("Form.checkFields").nullable(),
    dateRange_end: yup
        .date()
        .typeError("Form.checkFields")
        .min(yup.ref("dateRange_start"), "Form.startTimeBeforeEnd")
        .when(["interval", "hasNoEndDate"], {
            is: (interval, hasNoEndDate) => interval === "user-defined" && !hasNoEndDate,
            then: () => yup.date().typeError("Form.checkFields").min(yup.ref("dateRange_start"), "Form.startTimeBeforeEnd").nullable()
        })
        .nullable(),
    timeRange_start: yup
        .date()
        .typeError("Form.checkFields")
        .notOneOf([yup.ref("timeRange_end")]),
    timeRange_end: yup
        .date()
        .typeError("Form.checkFields")
        .min(yup.ref("timeRange_start"), "Form.startTimeBeforeEnd")
        .notOneOf([yup.ref("timeRange_start")], "Form.startTimeBeforeEnd")
        .required("Form.startTimeBeforeEnd")
        .test({
            name: "isEnd24Hour",
            message: "Form.checkFields",
            test: (value) => value && value.getHours() !== 0
        }),
    customWeeks: yup.string(),
    interval: yup.string()
});
