import {makeStyles} from "tss-react/mui";

/**
 * @type {object}
 */
export default makeStyles()((theme) => ({
    root: {
        color: theme.palette.grey[900],
        width: "100%",
        position: "relative"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
        position: "relative"
    },
    title: {
        ...theme.custom.rightPanel.title
    },
    divider: {
        ...theme.custom.rightPanel.divider
    },
    error: {
        color: theme.palette.error.main,
        fontSize: "0.688rem",
        margin: "1rem 2.5rem",
        maxWidth: "fit-content"
    },
    input: {
        width: "11rem"
    },
    inputDate: {
        width: "12.5rem"
    },
    formRow: {
        display: "flex",
        margin: "1rem 0",
        width: "420px"
    },
    ml1: {
        marginLeft: theme.spacing("m")
    },
    radioLabel: {
        fontSize: "0.688rem",
        textTransform: "uppercase",
        fontWeight: theme.typography.fontWeightLight
    },
    radioRoot: {
        "width": "12rem",
        "height": theme.spacing("m"),
        "margin": "1rem 0",
        "left": "-0.5rem",
        "position": "relative",
        "& .MuiRadio-root": {
            padding: theme.spacing("xs")
        }
    },
    next5: {
        fontSize: "0.75rem",
        color: theme.palette.grey[900],
        marginTop: theme.spacing("xxs")
    },
    conflicts: {
        fontSize: "0.75rem",
        color: theme.palette.error.main,
        marginBottom: theme.spacing("m"),
        width: "5rem"
    },
    buttonsWrapper: {
        display: "inline-flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        height: theme.custom.rightPanel.title.height,
        padding: "0 2.5rem 0 2.5rem"
    },
    button: {
        ...theme.custom.rightPanel.button
    },
    formWrapper: {
        overflowY: "auto",
        overflowX: "hidden",
        flex: 1
    },
    formContainer: {
        width: "100%",
        padding: "0 2.5rem"
    },
    switch: {
        "& .MuiFormControlLabel-root": {
            margin: 0,
            padding: `${theme.spacing("xs")} 0`
        }
    },
    switchText: {
        fontSize: "0.688rem",
        textTransform: "uppercase",
        fontWeight: theme.typography.fontWeightLight
    },
    calculating: {
        display: "flex",
        justifyContent: "center"
    },
    confirmDialogContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    secondText: {
        marginTop: theme.spacing("m"),
        marginBottom: theme.spacing("l")
    },
    blockSection: {
        margin: "1.875rem 2.5rem 0", // 30px
        width: "calc(100% - 4rem)",
        fontSize: "0.85rem"
    },
    block: {
        display: "flex",
        justifyContent: "space-between",
        margin: "1rem 2.5rem"
    }
}));
