// @ts-check
import {Button} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {PERMISSION, useSecurity} from "../../../utils/security";
import {selectDisciplineColors} from "../../fe_settings/fe_settings_selectors";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import DataTable from "../../shared/data_table/data_table";
import PermissionTooltip from "../../shared/permission_tooltip";
import useStyles from "./slot_table.styles";

const intervalOrder = {
    "none": 1,
    "weekdays": 2,
    "daily": 3,
    "weekly": 4,
    "user-defined": 5
};

/**
 * The disciplines slots table component. Rendered on /timeslot-planner when the navbar Toggle is on List
 *
 * @param {Object} props
 * @param {Array<TimeSlot>} props.timeslots
 * @param {Function} props.onEdit
 * @param {number} props.page
 * @param {Function} props.onSetPage
 * @return {React.ReactElement}
 */
const SlotTable = ({timeslots, onEdit, page, onSetPage}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {setDT, now, format, formatFromISO} = useContext(DateContext);

    // Redux
    const roomInfos = useSelector(selectRoomInfos);
    const disciplineColors = useSelector(selectDisciplineColors);

    const {isGranted} = useSecurity();

    // format
    const timeslotFormatted = timeslots.map((row) => {
        const {hourEndLocalTime, hourStartLocalTime, isEmergency, endDate: endDateBE, startDate: startDateBE, locations, interval} = row;

        const startTime = format(
            setDT(now(), {
                hour: hourStartLocalTime,
                minute: (hourStartLocalTime - Math.floor(hourStartLocalTime)) * 60,
                second: 0
            }),
            DATE_FORMATS.TIME
        );
        const endTime = format(
            setDT(now(), {
                hour: hourEndLocalTime,
                minute: (hourEndLocalTime - Math.floor(hourEndLocalTime)) * 60,
                second: 0
            }),
            DATE_FORMATS.TIME
        );

        const startDate = formatFromISO(startDateBE, DATE_FORMATS.DATE);
        const endDate = Boolean(endDateBE) && formatFromISO(endDateBE, DATE_FORMATS.DATE);

        // Set room names
        const roomNames = [];
        if (locations && locations.length) {
            for (const location of locations) {
                const room = roomInfos.find((item) => item.id === location);
                roomNames.push(room ? room.name : location); // if name is not found in roomInfos, set room id instead
            }
        }

        // Set discipline names
        const disciplineNames = [];
        if (row.healthcareServices && row.healthcareServices.length) {
            for (const hcserviceId of row.healthcareServices) {
                disciplineNames.push(t(`HealthcareService.${hcserviceId}`, hcserviceId));
            }
        }
        const bgColor = disciplineColors.find((el) => el.name === row.healthcareServices[0]);

        let formattedHealthcareServices = (
            <div style={{display: "flex"}}>
                <div className={classes.dot} style={{backgroundColor: bgColor?.hex}} />
                <div className={classes.text}>{disciplineNames.join(", ")}</div>
            </div>
        );
        const hcServiceText = disciplineNames.length
            ? `${t("TimeslotTablePage.emergency")} (${disciplineNames.join(", ")})`
            : t("App.allDisciplines");
        if (isEmergency) {
            formattedHealthcareServices = (
                <div style={{display: "flex"}}>
                    <div className={cx(classes.dot, classes.dotEmergency)} />
                    <div className={classes.text} title={hcServiceText}>
                        {hcServiceText}
                    </div>
                </div>
            );
        }
        const dataFormatted = {
            healthcareServices: formattedHealthcareServices,
            art: isEmergency ? t("TimeslotTablePage.emergency") : t("TimeslotTablePage.elective"),
            startDate,
            endDate,
            time: `${startTime} - ${endTime}`,
            interval: t(`App.slotInterval.${interval}`),
            cycle: interval === "user-defined" ? t(`App.slotInterval.cycle`, {count: row?.repeatAfterDays || 7 / 7}) : "",
            locations: roomNames.join(", ")
        };
        return {
            ...row,
            key: row._id,
            healthcareServices: disciplineNames.length ? disciplineNames : ["all"],
            locations: roomNames,
            interval: intervalOrder[interval],
            display: {
                ...dataFormatted,
                actions: (
                    <PermissionTooltip isAllowed={isGranted(PERMISSION.MODIFY_HCSERVICE)}>
                        <Button
                            classes={{text: classes.button}}
                            color="primary"
                            disabled={!isGranted(PERMISSION.MODIFY_HCSERVICE)}
                            size="small"
                            onClick={() => onEdit(row)}
                        >
                            {t("App.edit")}
                        </Button>
                    </PermissionTooltip>
                )
            },
            tooltip: dataFormatted
        };
    });
    const labels = [
        {id: "healthcareServices", label: t("TimeslotTablePage.discipline"), width: "250px", setTitle: true},
        {id: "art", label: t("TimeslotTablePage.art"), width: "100px", setTitle: true},
        {id: "startDate", label: t("TimeslotTablePage.start"), width: "100px", setTitle: true},
        {id: "endDate", label: t("TimeslotTablePage.end"), width: "100px", setTitle: true},
        {id: "time", label: t("TimeslotTablePage.time"), width: "100px", setTitle: true},
        {id: "interval", label: t("TimeslotTablePage.interval"), width: "100px", setTitle: true},
        {id: "cycle", label: t("TimeslotTablePage.cycle"), width: "100px", setTitle: true},
        {id: "locations", label: t("TimeslotTablePage.rooms"), width: "100px", setTitle: true},
        {id: "actions", label: t("TimeslotTablePage.actions"), width: "100px"}
    ];
    return (
        <DataTable
            data={timeslotFormatted}
            disableSortColumns={["art", "time", "surgeons", "rooms", "actions", "cycle"]}
            hasStickyPagination
            labels={labels}
            page={page}
            tableLeftRightPadding={64 + 64 + 32 * 2 + 32} // left menu 64, right menu 64, padding 32 * 2, buffer for scrollbar
            tableTopBottomPadding={64 + 117 + 118 + 72 + 32 + 54} // header 64, title 117, filters 118, add button row 72 + padding 32 + pagination 54
            onSetPage={onSetPage}
        />
    );
};

SlotTable.propTypes = {
    timeslots: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    page: PropTypes.number,
    onSetPage: PropTypes.func.isRequired
};
SlotTable.defaultProps = {
    page: 0
};
export default SlotTable;
