// @ts-check
import {useEffect} from "react";

/** @typedef {import("react-hook-form").UseFormClearErrors<any>} UseFormClearErrors*/
/** @typedef {import("react-hook-form").UseFormSetValue<any>} UseFormSetValue*/
/** @typedef {import("react-hook-form").UseFormResetField<any>} UseFormResetField*/

/**
 * A custom hook to handle when the user changes the selected interval
 *
 * @param {object} params
 * @param {Interval} params.selectedInterval
 * @param {UseFormResetField} params.resetField resetField from RFH
 * @param {UseFormSetValue} params.setValue The setter for RHF
 * @param {UseFormClearErrors} params.clearErrors The current start date in the form
 */
export const useSelectedInterval = ({selectedInterval, resetField, setValue, clearErrors}) => {
    useEffect(() => {
        if (selectedInterval === "none") {
            setValue("hasNoEndDate", false);
            clearErrors("dateRange_end");
        }
        if (selectedInterval !== "user-defined") {
            setValue("frequency", []);
            resetField("customWeeks");
        }
    }, [selectedInterval]);
};
