import {makeStyles} from "tss-react/mui";

/**
 * @type {object}
 */
export default makeStyles()((theme) => ({
    select: {
        width: "200px"
    },
    fullWidth: {
        width: "100%"
    },
    button: {
        ...theme.custom.rightPanel.button
    },
    formRow: {
        display: "flex",
        margin: "1rem 0",
        width: "420px",
        justifyContent: "space-between"
    },
    switchText: {
        fontSize: "0.688rem",
        textTransform: "uppercase",
        fontWeight: theme.typography.fontWeightLight
    },
    inputDate: {
        width: "12.5rem"
    },
    error: {
        color: theme.palette.error.main,
        fontSize: "0.688rem",
        marginBottom: theme.spacing("s"),
        display: "flex",
        flexDirection: "column"
    },
    block: {
        display: "flex",
        justifyContent: "space-between"
    },
    extraPaddingLeft: {paddingLeft: "15px"}
}));
