// @ts-check
import {Backdrop, Button} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {loadTimeslotsAction} from "../../pages/timeslot_table/timeslot_table_actions";
import {selectData, selectLoadStatus} from "../../pages/timeslot_table/timeslot_table_selectors";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import {isPending, isResolved} from "../../redux/utils/status";
import {PERMISSION, useSecurity} from "../../utils/security";
import {DisciplineSelect} from "../disciplines";
import {loadDisciplineOptionsAction} from "../disciplines/disciplines_actions";
import {loadRoomsAction} from "../rooms/rooms_actions";
import {selectRoomInfos} from "../rooms/rooms_selectors";
import CommonSelector from "../shared/common_selector/common_selector";
import DateRange from "../shared/date_range/date_range";
import Loading from "../shared/loading/loading";
import SlotTable from "./components/slot_table";
import useStyles from "./timeslot_table_canvas.styles";

/**
 * render TimeslotTableCanvas component
 *
 * @param {Object} props
 * @param {Function} props.onEdit
 * @param {Boolean} props.isSaved
 * @param {Function} props.onAdd
 * @param {Boolean} props.isLayerOpen
 * @return {React.ReactElement}
 */
const TimeslotTableCanvas = ({onEdit, isSaved, onAdd, isLayerOpen}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isGranted} = useSecurity();
    const {fromISO, format, endOf, startOf} = useContext(DateContext);

    const timeslots = useSelector(selectData);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const loadStatus = useSelector(selectLoadStatus);
    const roomInfos = useSelector(selectRoomInfos);

    const [disciplines, setDisciplines] = useState([]);
    const [category, setCategory] = useState(""); // "elective" or "emergency"
    const [rooms, setRooms] = useState([]);
    const [period, setPeriod] = useState({
        start: fromISO(config.MIN_SLOT_DATE),
        end: fromISO(config.MAX_SLOT_DATE)
    });
    const [periodOption, setPeriodOption] = useState("active");
    const [opOption, setOpOption] = useState(""); // "planned", "empty"
    const [page, setPage] = useState(0);

    useEffect(() => {
        dispatch(loadRoomsAction(organizationId));
        dispatch(loadDisciplineOptionsAction(organizationId));
    }, [organizationId]);

    useEffect(() => {
        if (organizationId && period.start.isValid && period.end.isValid) {
            const query = {
                organizationId: organizationId,
                healthcareServiceIds: disciplines.length ? disciplines : undefined,
                category: category ? category : undefined,
                appointmentOption: opOption ? opOption : undefined,
                locations: rooms.length ? rooms : undefined
            };
            if (periodOption) {
                query.rangeOption = periodOption;
                query.filterFrom = format(period.start, DATE_FORMATS.SYSTEM_DATE);
                query.filterTo = format(period.end, DATE_FORMATS.SYSTEM_DATE);
            }
            dispatch(loadTimeslotsAction(query));
        }
    }, [organizationId, disciplines, category, period, periodOption, opOption, rooms, isSaved]);

    const handleChangeDisciplines = (e) => {
        setDisciplines(e.target.value);
        setPage(0);
    };

    const handleResetDisciplines = () => {
        setDisciplines([]);
        setPage(0);
    };
    const handleChangeCategory = (e) => {
        setCategory(e.target.value);
        setPage(0);
    };
    const handleResetCategory = () => {
        setCategory("");
        setPage(0);
    };

    const handleChangePeriod = (date, name) => {
        setPeriod((prevState) => ({
            ...prevState,
            [name]: date
        }));
        setPage(0);
    };
    const handleChangePeriodOption = (e) => {
        setPeriodOption(e.target.value);
        setPage(0);
    };
    const handleChangeOpOption = (e) => {
        setOpOption(e.target.value);
        setPage(0);
    };
    const handleResetOpOption = () => {
        setOpOption("");
        setPage(0);
    };
    const handleChangeRooms = (e) => {
        setRooms(e.target.value);
        setPage(0);
    };
    const handleResetRooms = () => {
        setRooms([]);
        setPage(0);
    };

    const handleReset = () => {
        setDisciplines([]);
        setRooms([]);
        setCategory("");
        setOpOption("");
        setPeriodOption("active");
        setPeriod({
            start: fromISO(config.MIN_SLOT_DATE),
            end: fromISO(config.MAX_SLOT_DATE)
        });
    };
    const disciplineSelect = (
        <DisciplineSelect
            // @ts-ignore
            disabled={isPending(loadStatus)}
            // @ts-ignore
            multiple
            // @ts-ignore
            styles={{input: classes.input}}
            // @ts-ignore
            values={disciplines}
            // @ts-ignore
            onReset={handleResetDisciplines}
            // @ts-ignore
            onSelect={handleChangeDisciplines}
        />
    );
    const categoryList = [
        {label: t("TimeslotTablePage.elective"), value: "elective"},
        {label: t("TimeslotTablePage.emergency"), value: "emergency"}
    ];
    const categorySelect = (
        <div className={classes.form}>
            <CommonSelector
                disabled={isPending(loadStatus)}
                items={categoryList}
                placeholder={t("App.pleaseSelect")}
                styles={{input: classes.input}}
                title={t("TimeslotTablePage.category")}
                value={category}
                onChange={handleChangeCategory}
                onReset={handleResetCategory}
            />
        </div>
    );
    const roomsList = roomInfos.map((room) => ({label: room.name, value: room.id}));
    const roomSelect = (
        <div className={classes.ml1}>
            <CommonSelector
                disabled={isPending(loadStatus)}
                items={roomsList}
                multiple
                placeholder={t("App.roomPlaceholder")}
                styles={{input: classes.input}}
                title={t("App.roomLabel")}
                value={rooms}
                onChange={handleChangeRooms}
                onReset={handleResetRooms}
            />
        </div>
    );

    const dateRangeLabels = {
        start: t("App.dateRange"),
        end: null
    };
    const dateRange = (
        <div className={classes.ml1}>
            <DateRange
                max={endOf(fromISO(config.MAX_SLOT_DATE), "day")}
                min={startOf(fromISO(config.MIN_SLOT_DATE), "day")}
                // stlyes={{inputDate: classes.inputDate, marginBetween: classes.marginBetween}}
                title={dateRangeLabels}
                values={period}
                onChange={handleChangePeriod}
            />
        </div>
    );
    const dateOptionList = [
        {label: t("TimeslotTablePage.starting"), value: "starting"},
        {label: t("TimeslotTablePage.active"), value: "active"},
        {label: t("TimeslotTablePage.ending"), value: "ending"}
    ];
    const selectPeriodOption = (
        <div className={classes.ml1}>
            <CommonSelector
                disabled={isPending(loadStatus)}
                disableReset
                items={dateOptionList}
                styles={{input: classes.input}}
                title={t("TimeslotTablePage.dateFilter")}
                value={periodOption}
                onChange={handleChangePeriodOption}
            />
        </div>
    );
    const opOptionList = [
        {label: t("TimeslotTablePage.planned"), value: "planned"},
        {label: t("TimeslotTablePage.empty"), value: "empty"}
    ];
    const selectOpOption = (
        <div className={classes.ml1}>
            <CommonSelector
                disabled={isPending(loadStatus)}
                items={opOptionList}
                placeholder={t("App.pleaseSelect")}
                styles={{input: classes.input}}
                title={t("TimeslotTablePage.opFilter")}
                value={opOption}
                onChange={handleChangeOpOption}
                onReset={handleResetOpOption}
            />
        </div>
    );

    const noResult = (
        <div className={classes.form}>
            <div className={classes.info}>{t("TimeslotTablePage.noResult")}</div>
            <div
                className={classes.clickText}
                role={"button"}
                tabIndex={0}
                onClick={handleReset}
                onKeyDown={(e) => e.key === "Enter" && handleReset()}
            >
                {t("TimeslotTablePage.reset")}
            </div>
        </div>
    );

    return (
        <div className={classes.canvasRoot}>
            <div className={classes.title}>{t("TimeslotTablePage.title")}</div>
            {isPending(loadStatus) && (
                <Backdrop className={classes.loading} open>
                    <Loading />
                </Backdrop>
            )}
            <div className={classes.tableArea}>
                <div className={classes.filterWrapper}>
                    <div className={classes.filterRow}>
                        {disciplineSelect}
                        {selectPeriodOption}
                        {dateRange}
                        {selectOpOption}
                        {categorySelect}
                        {roomSelect}
                    </div>
                </div>
                <div
                    className={cx(classes.actions, {
                        [classes.layerOpen]: isLayerOpen
                    })}
                >
                    <Button color="primary" disabled={!isGranted(PERMISSION.MODIFY_HCSERVICE)} variant="contained" onClick={(e) => onAdd()}>
                        {t("TimeslotTablePage.addSlot")}
                    </Button>
                </div>
                {isResolved(loadStatus) && timeslots && !timeslots.length && noResult}
                {
                    <div className={classes.tableWrapper}>
                        <SlotTable page={page} timeslots={timeslots} onEdit={onEdit} onSetPage={setPage} />
                    </div>
                }
            </div>
        </div>
    );
};
TimeslotTableCanvas.propTypes = {
    onEdit: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    isSaved: PropTypes.bool,
    isLayerOpen: PropTypes.bool
};
export default TimeslotTableCanvas;
