import PropTypes from "prop-types";
import React, {Fragment} from "react";

import {timeslots as timeslotsPropType} from "../../pages/timeslots/timeslots_proptypes";
import useStyles from "./timeslot_box.styles";

/**
 * Render TimeslotBox component
 *
 * @param {Object} props
 * @param {TimeSlot} props.slot
 * @param {String} props.disciplineColor
 * @param {string} props.topPosition
 * @param {Number} props.startPercent
 * @param {Number} props.endPercent
 * @param {Number} props.barHeight
 * @param {{header: React.ReactElement, subheader: React.ReactElement}} props.contents
 * @param {Function} props.onClick
 * @param {Boolean} props.isSelectedSlot
 * @return {React.ReactElement}
 */
export const TimeslotBox = ({
    slot,
    disciplineColor,
    topPosition,
    startPercent,
    endPercent,
    barHeight,
    contents,
    onClick,
    isSelectedSlot
}) => {
    const {classes, cx} = useStyles();
    const left = "calc(" + startPercent + "% + 1px)";
    const width = "calc(" + (endPercent - startPercent) + "% - 1px)";
    const height = `${barHeight}rem`;

    // Set discipline color on top right
    const disciplineLine = disciplineColor ? (
        <div className={classes.disciplineLine} style={{backgroundColor: disciplineColor}} />
    ) : (
        <div className={classes.disciplineLine} />
    );

    const handleClick = () => {
        onClick && onClick(slot);
    };

    const content = (
        <Fragment>
            <div
                className={cx(classes.contentRow, classes.medium, {
                    [classes.contentRowEmergency]: slot.isEmergency
                })}
                data-testid="header"
            >
                {contents.header}
            </div>
            <div
                className={cx(classes.contentRow, {
                    [classes.contentRowEmergency]: slot.isEmergency
                })}
                data-testid="subheader1"
            >
                {contents.subheader}
            </div>
        </Fragment>
    );

    const GhostLayer = () => <div className={cx(classes.root, classes.ghostLayer)} style={{left, width}} />;

    return (
        <>
            <GhostLayer />
            <div
                className={cx(classes.root, {
                    [classes.rootSelectedSlot]: isSelectedSlot,
                    [classes.rootEmergency]: slot.isEmergency,
                    [classes.rootHovered]: !slot.isEmergency
                })}
                style={{top: topPosition, left, height, width}}
            >
                <div
                    className={cx(classes.box)}
                    role={"button"}
                    tabIndex={0}
                    onClick={handleClick}
                    onKeyDown={(e) => e.key === "Enter" && handleClick()}
                >
                    {disciplineLine}
                    <div className={classes.contentWrapper}>{content}</div>
                </div>
            </div>
        </>
    );
};

TimeslotBox.propTypes = {
    slot: timeslotsPropType.isRequired,
    topPosition: PropTypes.string.isRequired,
    startPercent: PropTypes.number.isRequired,
    endPercent: PropTypes.number.isRequired,
    barHeight: PropTypes.number.isRequired, // in rem
    contents: PropTypes.shape({
        header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
    }).isRequired,
    onClick: PropTypes.func,
    disciplineColor: PropTypes.string,
    isSelectedSlot: PropTypes.bool.isRequired
};

export default TimeslotBox;
