import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {useSelector} from "react-redux";

import {selectThemeLocationInfos} from "../../../fe_settings/fe_settings_selectors";
import {selectRoomInfos} from "../../../rooms/rooms_selectors";
import useStyles from "../../timeslot_canvas.styles";

/**
 * Calculates the y axis elements
 * @param {object} coreValues
 * @param {array} opRooms
 * @return {JSX}
 */
export const YaxisElement = ({coreValues, opRooms}) => {
    const {classes} = useStyles();

    const roomInfos = useSelector(selectRoomInfos);
    const locationInfos = useSelector(selectThemeLocationInfos);

    const yaxisElements = [];

    if (!coreValues.rowHeight || opRooms.length === 0) return null;
    for (const [key, value] of opRooms.entries()) {
        const room = roomInfos.find((item) => item.id === value);
        const roomLabel = locationInfos?.[room.id]?.label || room?.name || null;
        const top = key * coreValues.rowHeight;
        const height = coreValues.rowHeight;

        yaxisElements.push(
            <div className={classes.roomLabel} key={key} style={{top: top + "rem", height: height + "px"}}>
                <div className={classes.yaxisLabel}>
                    <div data-testid="y-axis-room-label">{room ? roomLabel : value}</div>
                </div>
            </div>
        );
    }
    return <Fragment>{yaxisElements}</Fragment>;
};
YaxisElement.propTypes = {
    opRooms: PropTypes.arrayOf(PropTypes.string),
    coreValues: PropTypes.shape({
        rowHeight: PropTypes.number
    })
};

export default YaxisElement;
