/* eslint-disable valid-jsdoc */
// @ts-check
/**
 * @fileoverview date provider
 *
 * usage:
 * Provider
 * <code>
 *     const timezone = "Asia/Tokyo";
 *
 *     <DateProvider timezone={timezone}>
 *         <App />
 *     </DateProvider>
 * </code>
 *
 * Child component
 * <code>
 *     const MyComponent = (date) => {
 *        const {getDT} = useContext(DateContext);
 *
 *        const hour = getDT(date, "hour");
 *     };
 * </code>
 */

import {DateTime, Settings} from "luxon";
import {node, string} from "prop-types";
import React from "react";

import DateContext, {DEFAULT_LOCALE, DEFAULT_TIMEZONE} from "./date_context";
import {luxonToken} from "./date_format";

/**
 * @typedef {Object} Locale
 * @property {String} locale
 */

/** @typedef {(DateProperties & Locale)} DatePropertiesWithLocale */

/**
 * DateProvider component
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {String} props.timezone
 * @param {String} props.locale
 * @return {React.ReactElement}
 */
const DateProvider = ({children, timezone = DEFAULT_TIMEZONE, locale = DEFAULT_LOCALE}) => {
    Settings.defaultLocale = locale;
    Settings.defaultZone = timezone;

    /** @type FromISO */
    const fromISO = (dateISO) => DateTime.fromISO(dateISO, {setZone: true});

    /** @type FromFormat */
    const fromFormat = (dateISO, format) => {
        const tokenKey = luxonToken[format][locale] || luxonToken[format].de;
        return DateTime.fromFormat(dateISO, tokenKey, {zone: timezone});
    };

    /** @type FromJSDate */
    const fromJSDate = (date) => DateTime.fromJSDate(date, {zone: timezone});

    /** @type FromMillis */
    const fromMillis = (milliseconds, format) => DateTime.fromMillis(milliseconds, {zone: "utc"}).toFormat(format);

    /** @type FormatFromISO */
    const formatFromISO = (dateISO, format) => {
        const tokenKey = luxonToken[format][locale] || luxonToken[format].de; // Change luxontoken for LUXON_TOKEN
        return DateTime.fromISO(dateISO, {setZone: true}).toFormat(tokenKey);
    };

    /** @type FormatFromISOSetTimezone */
    const formatFromISOSetTimezone = (dateISO, format, timezone) => {
        const tokenKey = luxonToken[format][locale] || luxonToken[format].de; // Change luxontoken for LUXON_TOKEN
        return DateTime.fromISO(dateISO, {zone: timezone}).toFormat(tokenKey);
    };

    /** @type FormatFromJSDate */
    const formatFromJSDate = (date, format) => {
        const tokenKey = luxonToken[format][locale] || luxonToken[format].de; // Change luxontoken for LUXON_TOKEN
        return DateTime.fromJSDate(date, {zone: timezone}).toFormat(tokenKey);
    };

    /** @type Format */
    const format = (date, format) => {
        const tokenKey = luxonToken[format][locale] || luxonToken[format].de;
        return date.toFormat(tokenKey);
    };

    /** @type Now */
    const now = () => DateTime.now().setZone(timezone); // return now in organization timezone

    /** @type AreSame */
    const areSame = (a, b, unit) => a.setZone(timezone).hasSame(b.setZone(timezone), unit);

    /** @type StartOf */
    const startOf = (date, unit) => date.startOf(unit);

    /** @type EndOf */
    const endOf = (date, unit) => date.endOf(unit);

    /** @type Plus */
    const plusDT = (date, unit, num) => date.plus({[unit]: num});

    /** @type Minus */
    const minusDT = (date, unit, num) => date.minus({[unit]: num});

    /** @type Get */
    const getDT = (date, unit) => date.setZone(timezone)[unit];

    /** @type SetDate */
    const setDT = (date, values) => date.set(values);

    /** @type Diff */
    const diffDT = (a, b, unit) => a.diff(b, unit).toObject()[unit];

    /** @type GetLuxonToken */
    const getLuxonToken = (format) => luxonToken[format][locale] || luxonToken[format].de;

    /** @type {DateProperties} DateFunctions */
    const DateProperties = {
        fromISO,
        fromFormat,
        fromJSDate,
        formatFromISO,
        formatFromJSDate,
        format,
        now,
        areSame,
        startOf,
        endOf,
        plusDT,
        minusDT,
        getDT,
        setDT,
        diffDT,
        timezone,
        fromMillis,
        getLuxonToken,
        formatFromISOSetTimezone
    };

    return <DateContext.Provider value={{locale, ...DateProperties}}>{children}</DateContext.Provider>;
};

DateProvider.propTypes = {
    children: node.isRequired,
    timezone: string,
    locale: string
};

export default DateProvider;
