export const luxonToken = {
    SYSTEM_DATE: {"de": "yyyy-LL-dd", "en-US": "yyyy-LL-dd"},
    ISO_DATE: {"de": "yyyy-LL-dd'T'TTZZ", "en-US": "yyyy-LL-dd'T'TTZZ"},
    DATE_FORMAT_LONG: {
        "de": "ccc, dd.LL.yyyy",
        "en-US": "ccc, LL/dd/yyyy"
    },
    DATE_FORMAT_FULL_WEEKDAY: {
        "de": "cccc, dd.LL.yyyy",
        "en-US": "cccc, LL/dd/yyyy"
    },
    DATE_FORMAT: {
        "de": "dd.LL.yyyy",
        "en-US": "LL/dd/yyyy"
    },
    DATE_FORMAT_SHORT: {
        "de": "dd.LL.",
        "en-US": "LL/dd"
    },
    DATE_FORMAT_SHORT_ABBREVIATED: {
        "de": "d. LLL.",
        "en-US": "LLL. d"
    },
    DATE_FORMAT_MEDIUM: {
        "de": "dd.LL.yy",
        "en-US": "LL/dd/yy"
    },
    BIRTH_DATE_FORMAT: {
        "de": "dd.LL.yyyy",
        "en-US": "yyyy-LL-dd"
    },
    BIRTH_DATE_SHORT_FORMAT: {
        "de": "LL.yyyy",
        "en-US": "yyyy-LL"
    },
    TIME_FORMAT: {
        "de": "T",
        /**
         * The AM/PM format (08:24 AM 03:40 PM) is more common for the English speaking countries.
         * But we don't use the AM/PM format due to the limited space.
         */
        "en-US": "T"
    },
    WEEK_NAME: {
        "de": "ccc",
        "en-US": "ccc"
    },
    DATE_TIME_FORMAT: {
        "de": "dd.LL.yyyy, T",
        "en-US": "LL/dd/yyyy, T"
    },
    DATE_TIME_SHORT_FORMAT: {
        "de": "dd.LL.(T)",
        "en-US": "LL/dd (T)"
    }
};

export const DATE_FORMATS = Object.freeze({
    SYSTEM_DATE: "SYSTEM_DATE",
    DATE_LONG: "DATE_FORMAT_LONG",
    DATE_FULL_WEEKDAY: "DATE_FORMAT_FULL_WEEKDAY",
    DATE: "DATE_FORMAT",
    DATE_SHORT: "DATE_FORMAT_SHORT",
    DATE_SHORT_ABBREVIATED: "DATE_FORMAT_SHORT_ABBREVIATED",
    DATE_MEDIUM: "DATE_FORMAT_MEDIUM",
    BIRTH_DATE_FORMAT: "BIRTH_DATE_FORMAT",
    BIRTH_DATE_SHORT_FORMAT: "BIRTH_DATE_SHORT_FORMAT",
    TIME: "TIME_FORMAT",
    WEEK_NAME: "WEEK_NAME",
    ISO_DATE: "ISO_DATE",
    DATE_TIME_FORMAT: "DATE_TIME_FORMAT"
});
