// @ts-check
import PropTypes from "prop-types";
import React, {Fragment, useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../../contexts/dates";
import {timeslots as timeslotsPropType} from "../../../../pages/timeslots/timeslots_proptypes";
import {selectCoreValues, selectOpRoomIds, selectSelectedDate} from "../../../../pages/timeslots/timeslots_selectors";
import {PERMISSION, useSecurity} from "../../../../utils/security";
import {selectDisciplineOptions} from "../../../disciplines/disciplines_selectors";
import {selectDisciplineColors} from "../../../fe_settings/fe_settings_selectors";
import TimeslotBox from "../../../timeslot_box/timeslot_box";
import useStyles from "./timeslot_box_container.styles";

/**
 * Returns all timeslot boxes
 *
 * @param {object} props The props
 * @param {Array} props.slots
 * @param {function} props.onClick
 * @return {React.ReactElement[]}
 */
const TimeslotBoxContainer = ({onClick, slots}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const {isGranted} = useSecurity();
    const {fromISO, areSame, startOf, diffDT, formatFromISO} = useContext(DateContext);

    // Redux
    const disciplineOptions = useSelector(selectDisciplineOptions);
    const selectedDate = useSelector(selectSelectedDate);
    const opRooms = useSelector(selectOpRoomIds);
    const coreValues = useSelector(selectCoreValues);
    const disciplineColors = useSelector(selectDisciplineColors);

    const isEditAllowed = isGranted(PERMISSION.MODIFY_HCSERVICE);

    const slotBoxes = [];
    for (const slot of slots) {
        const {start, end} = slot.preCalcDates[0];
        const slotStart = fromISO(start);
        const slotEnd = fromISO(end);

        if (!areSame(slotStart, selectedDate, "day")) {
            continue;
        }
        const startOfDay = startOf(selectedDate, "day");
        const durationSlotStart = diffDT(slotStart, startOfDay, "seconds");
        const startPercent = (durationSlotStart / ((coreValues.canvasEnd + 1) * 60 * 60)) * 100;
        const durationSlotEnd = diffDT(slotEnd, startOfDay, "seconds");
        const endPercent = (durationSlotEnd / ((coreValues.canvasEnd + 1) * 60 * 60)) * 100;

        const rowNumber = opRooms.indexOf(slot.locations?.[0]);
        const endDate = slot.endDate ? formatFromISO(slot.endDate, DATE_FORMATS.DATE) : t("DateRange.noDate");
        const period = formatFromISO(slot.startDate, DATE_FORMATS.DATE) + " - " + endDate;

        // discipline original names from the backend
        const disciplineOriginalNames = slot.healthcareServices.map((id) => disciplineOptions.find((item) => item.key === id)?.text || id);

        // Set names from the translation, if translation is not found, set from the original names
        const disciplineName = slot.isEmergency
            ? t("TimeslotsPage.emergency", {
                  disciplines:
                      disciplineOriginalNames?.[0] === "all" ? t("TimeslotsPage.allDisciplines") : disciplineOriginalNames?.join(", ")
              })
            : t([`HealthcareService.${slot.healthcareServices[0]}`, disciplineOriginalNames[0]]);
        const repeats = t(`TimeslotsPage.${slot.interval}`);

        const contents = {
            header: <div className={classes.header}>{disciplineName}</div>,
            subheader: (
                <Fragment>
                    {period} &nbsp;/&nbsp;{repeats}
                </Fragment>
            )
        };
        const disciplineColor = disciplineColors.find((item) => slot.healthcareServices[0] === item.name);
        const hexDiscipline = slot.isEmergency ? "#f32727" : disciplineColor?.hex; // @TODO #14863: Set emergency color from theme

        if (rowNumber !== -1) {
            slotBoxes.push(
                <TimeslotBox
                    barHeight={3.5}
                    contents={contents}
                    disciplineColor={hexDiscipline}
                    endPercent={endPercent}
                    isSelectedSlot={false}
                    key={"timeslot-" + slot._id}
                    slot={slot}
                    startPercent={startPercent}
                    topPosition={"10px"}
                    onClick={isEditAllowed ? onClick : null}
                />
            );
        }
    }

    return slotBoxes;
};

TimeslotBoxContainer.propTypes = {
    onClick: PropTypes.func.isRequired,
    slots: PropTypes.arrayOf(timeslotsPropType)
};

export default TimeslotBoxContainer;
