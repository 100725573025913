import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    canvasRoot: {
        height: "100%",
        width: "100%"
    },
    title: {
        fontSize: "23px",
        fontWeight: theme.typography.fontWeightMedium,
        padding: "2.5rem 2rem",
        borderBottom: `2px solid ${theme.palette.grey[100]}`
    },
    tableArea: {
        height: "calc(100% - 116.5px)"
    },
    table: {
        marginTop: "6rem"
    },
    form: {
        marginLeft: theme.spacing("m"),
        display: "flex"
    },
    input: {
        height: "1.7rem",
        padding: `${theme.spacing("xxs")} 0`
    },
    placeholder: {
        color: theme.palette.grey[500],
        fontSize: "0.875rem"
    },
    menuWrapper: {
        maxHeight: "24rem"
    },
    menu: {
        fontSize: "0.875rem"
    },
    filterWrapper: {
        "padding": `${theme.spacing("l")}`,
        "& .MuiFormHelperText-root.Mui-error": {
            fontSize: "0.688rem"
        }
    },
    filterRow: {
        display: "flex",
        marginBottom: theme.spacing("s")
    },
    checkboxLabel: {
        fontSize: "0.688rem",
        textTransform: "uppercase",
        fontWeight: theme.typography.fontWeightLight
    },
    marginBetween: {
        marginLeft: theme.spacing("s"),
        display: "flex"
    },
    inputDate: {
        width: "12.5rem"
    },
    ml1: {
        marginLeft: theme.spacing("m")
    },
    loading: {
        zIndex: theme.zIndex.drawer + 1
    },
    clickText: {
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        fontWeight: theme.typography.fontWeightMedium,
        marginLeft: theme.spacing("s"),
        cursor: "pointer",
        display: "flex",
        alignItems: "flex-end"
    },
    optionIcon: {
        fontSize: "1rem",
        position: "relative"
    },
    info: {
        fontSize: "0.75rem",
        color: theme.palette.grey[700],
        marginLeft: theme.spacing("xs")
    },
    tableWrapper: {
        padding: `0 ${theme.spacing("l")} ${theme.spacing("l")}`,
        height: "calc(100% - 190px)",
        position: "relative"
    },
    actions: {
        margin: "1rem 2rem",
        display: "flex",
        justifyContent: "flex-end"
    },
    layerOpen: {
        width: "calc(100% + 436px)"
    }
}));
