// @ts-check
import {Button, CircularProgress} from "@mui/material";
import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {checkAndSaveTimeslotsAction} from "../../pages/timeslots/timeslots_actions";
import {timeslots as timeslotsPropType} from "../../pages/timeslots/timeslots_proptypes";
import {selectCheckStatus, selectPreCheck, selectSelectedDate} from "../../pages/timeslots/timeslots_selectors";
import {isPending, isResolved} from "../../redux/utils/status";
import {selectDisciplinesIdsAndName} from "../disciplines/disciplines_selectors";
import {selectThemeStartTimeOfTheDay} from "../fe_settings/fe_settings_selectors";
import {selectRoomInfos} from "../rooms/rooms_selectors";
import ConfirmDialog from "../shared/confirm_dialog/comfirm_dialog";
import DateConflictList from "../shared/date_conflict_list";
import DateList from "../shared/date_list";
import InfoBlock from "../shared/info_block/info_block";
import {getOptionsSlotInterval} from "../shared/radio_buttons/options_slot_interval";
import {TimeslotDialogForm} from "./components/time_slot_dialog_form";
import {
    checkForConflictDates,
    formatDisciplines,
    formatPeriod,
    formatTime,
    getDefaultValues,
    getDefaultValuesWithTimeslot
} from "./helpers";
import useStyles from "./timeslot_dialog.styles";

/**
 * search op for change
 *
 * @param {Object} props
 * @param {TimeSlot} props.timeslot
 * @return {React.ReactElement}
 */
export const TimeslotDialog = ({timeslot}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {format, startOf, endOf, now, setDT, fromISO} = useContext(DateContext);

    // redux
    const startTimeOfTheDay = useSelector(selectThemeStartTimeOfTheDay);
    const checkStatus = useSelector(selectCheckStatus);
    const preCheck = useSelector(selectPreCheck);
    const selectedDate = useSelector(selectSelectedDate);
    const disciplinesIdsAndName = useSelector(selectDisciplinesIdsAndName);
    const rooms = useSelector(selectRoomInfos);

    const period = useMemo(() => formatPeriod({selectedDate, startOf, endOf}), [selectedDate, startOf, endOf]); // Would this useMemo always run because selectedDate is an object?
    const time = useMemo(() => formatTime({now, startTimeOfTheDay, setDT}), [now, startTimeOfTheDay, setDT]);
    const conflictDates = checkForConflictDates({isResolved, checkStatus, preCheck});

    const [open, setOpen] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const handleConfirm = () => setOpen(false);
    const handleCancel = () => {
        setOpen(false);
    };

    const handleSave = (dataToPost, onlyCheck) => {
        const dateFormatted = format(selectedDate, DATE_FORMATS.SYSTEM_DATE);
        dispatch(checkAndSaveTimeslotsAction(dataToPost, dateFormatted, onlyCheck));
    };

    // format form data
    const disciplinesTranslated = formatDisciplines(disciplinesIdsAndName);
    const roomsFormatted = rooms.map((el) => ({value: el.id, label: el.name}));
    const intervalsFormatted = getOptionsSlotInterval().map(({label, value}) => ({label: t(label), value}));

    const defaultValues = useMemo(
        () => (timeslot ? getDefaultValuesWithTimeslot({timeslot, now, setDT, fromISO}) : getDefaultValues({period, time})),
        [timeslot]
    );

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.title}>{t("TimeslotDialog.title")}</div>
                <div className={classes.divider} />
                <div className={classes.formWrapper}>
                    <div className={classes.formContainer}>
                        <div className={classes.formRow}>
                            <TimeslotDialogForm
                                defaultValues={defaultValues}
                                disciplines={disciplinesTranslated}
                                handleSave={handleSave}
                                hasConflictsDates={conflictDates.length > 0}
                                intervalList={intervalsFormatted}
                                rooms={roomsFormatted}
                                timeslot={timeslot}
                            />
                        </div>
                    </div>
                    <div className={classes.divider} />
                    <div className={classes.blockSection}>
                        <InfoBlock title={t("TimeslotDialog.conflicts", {count: conflictDates?.length})}>
                            <Fragment>
                                {isPending(checkStatus) && (
                                    <div className={classes.calculating}>
                                        <CircularProgress size={20} />
                                    </div>
                                )}
                                {isResolved(checkStatus) && conflictDates && (
                                    <DateConflictList className={classes.conflicts} list={conflictDates} />
                                )}
                            </Fragment>
                        </InfoBlock>
                    </div>
                    <div className={classes.divider} />
                    <div className={classes.blockSection}>
                        <InfoBlock title={t("TimeslotDialog.next5")}>
                            <Fragment>
                                {isPending(checkStatus) && (
                                    <div className={classes.calculating}>
                                        <CircularProgress size={20} />
                                    </div>
                                )}
                                {isResolved(checkStatus) && preCheck && preCheck.preCalcDates && (
                                    <DateList className={classes.next5} list={preCheck.preCalcDates} />
                                )}
                            </Fragment>
                        </InfoBlock>
                    </div>
                </div>
                <div className={classes.divider} />
                <div className={classes.buttonsWrapper}>
                    <Button className={classes.button} color="primary" form="timeslot" type="submit" variant="contained">
                        {t("TimeslotDialog.save")}
                    </Button>
                </div>
            </div>
            {/* 
                The feature is missing: https://dev.azure.com/next-data-service/13394-nextOR/_wiki/wikis/13394-NextOR-Wiki/970/Surgeon-availability-for-multiple-disciplines?anchor=**1.surgeon-availability-is-inherited-from-the-discipline-and-cannot-be-edited.** 
                It is okay so far if the customer does not use the availabilityMode: fromHealthcareService.
                But if a new customer comes and uses the availabilityMode: fromHealthcareService, this feature needs to be implemented again

            */}
            <ConfirmDialog open={open} title={t("TimeslotDialog.ConfirmDialog.title")} onCancel={handleCancel} onConfirm={handleConfirm}>
                <div className={classes.confirmDialogContent}>
                    <p>{t("TimeslotDialog.ConfirmDialog.text1")}</p>
                    <p className={classes.secondText}>{t("TimeslotDialog.ConfirmDialog.text2")}</p>
                </div>
            </ConfirmDialog>
        </div>
    );
};

TimeslotDialog.propTypes = {
    timeslot: timeslotsPropType
};
export default TimeslotDialog;
