// @ts-check
import {createContext} from "react";
import io from "socket.io-client";

/** @typedef {import("socket.io-client").Socket} Socket */
/**
 * Initiate websocket between orchestrator
 * @param {string} organizationId
 * @param {string} token
 * @return {Socket} https://socket.io/docs/v4/client-api/#iourl
 */
export const setupWebSocket = (organizationId, token) => {
    const socket = io(global.ORCHESTRATOR_WS_URL_EXTERNAL, {
        transports: ["websocket"],
        path: global.ORCHESTRATOR_WS_PATH_EXTERNAL,
        auth: {
            organizationId,
            token
        },
        withCredentials: true
    });

    return socket;
};

/** @type {React.Context} */
export const SocketContext = createContext(undefined);

/**
 * @description If we don't pass a default value to createContext the ts compiler would show an error
 * @link // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/24509#issuecomment-382213106
 */
